import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";

import { RobotoTypography } from "@/components";
import {
  TagAndMetadataAutocomplete,
  AutocompleteType,
} from "@/components/TagAndMetadataAutocomplete";
import { Comparator } from "@/types";

import { SearchField, UISimpleCondition } from "./types";

interface InnerContentProps {
  availableMetadataKeys: string[];
  searchField: SearchField;
  dateValue: Dayjs | null;
  condition: UISimpleCondition;
  comparator: Comparator;
  handleChange: (event: { target: { name: string; value: string } }) => void;
  handleDatePickerChange: (date: Dayjs | null) => void;
  metadataKey: string;
  metadataValue: string;
  metadataType: "string" | "boolean" | "number";
}

export const InnerContent: React.FC<InnerContentProps> = ({
  availableMetadataKeys,
  searchField,
  dateValue,
  condition,
  comparator,
  handleChange,
  metadataKey,
  metadataValue,
  metadataType,
  handleDatePickerChange,
}) => {
  const theme = useTheme();

  const currentTimezone = dayjs.tz.guess();
  const todayAt8AMLocal = dayjs()
    .tz(currentTimezone)
    .startOf("day")
    .add(8, "hour");

  let innerContent = <></>;

  const emptyComparators: Comparator[] = [
    "EXISTS",
    "NOT_EXISTS",
    "IS_NULL",
    "IS_NOT_NULL",
  ];

  const isEmptyComparator = emptyComparators.includes(comparator);

  if (searchField.type === "date") {
    innerContent = (
      <DatePicker
        sx={{ width: "100%", maxWidth: "300px" }}
        data-cy={"datePicker"}
        timezone={currentTimezone}
        defaultValue={todayAt8AMLocal}
        value={dateValue}
        onChange={(dayjsDate) => {
          handleDatePickerChange(dayjsDate);
        }}
        label={currentTimezone}
        slotProps={{ textField: { size: "small" } }}
      />
    );
  } else if (searchField.type === "metadata") {
    innerContent = (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "300px",
        }}
      >
        <Autocomplete
          data-cy={"metadataKey"}
          sx={{ marginBottom: theme.spacing(1) }}
          size={"small"}
          options={availableMetadataKeys}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Key"
              onChange={(event) => {
                const newEvent = {
                  target: {
                    name: "metadataKey",
                    value: event.target.value,
                  },
                };
                handleChange(newEvent);
              }}
            />
          )}
          inputValue={metadataKey}
          onChange={(_, value) => {
            const newEvent = {
              target: {
                name: "metadataKey",
                value: value ?? "",
              },
            };
            handleChange(newEvent);
          }}
        />
        {!isEmptyComparator && (
          <>
            <TextField
              sx={{ marginBottom: theme.spacing(1) }}
              name={"metadataValue"}
              data-cy={"metadataValue"}
              placeholder="Value"
              value={metadataValue}
              onChange={handleChange}
              size="small"
              inputProps={{
                autoComplete: "off",
              }}
            />
            <Select
              value={metadataType}
              name={"selectMetadataType"}
              size="small"
              // this event handler will work just fine, the MUI type is too restrictive
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={handleChange}
            >
              <MenuItem value={"string"}>string</MenuItem>
              <MenuItem value={"boolean"}>boolean</MenuItem>
              <MenuItem value={"number"}>number</MenuItem>
            </Select>
          </>
        )}
      </Box>
    );
  } else if (searchField.type === "tags") {
    innerContent = (
      <TagAndMetadataAutocomplete
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
          const newEvent = { ...event };
          newEvent.target.name = "valueInput";
          newEvent.target.value = value;

          handleChange(newEvent);
        }}
        onTagCreated={(tag) => {
          const syntheticEvent = {
            target: {
              name: "valueInput",
              value: tag,
            },
          };

          handleChange(syntheticEvent);
        }}
        autocompleteType={AutocompleteType.DatasetTags}
        sx={{ width: "100%", maxWidth: "300px" }}
        hideAddButton={true}
        clearInputOnSelect={false}
      />
    );
  } else {
    innerContent = (
      <TextField
        data-cy={"valueInput"}
        sx={{ width: "100%", maxWidth: "300px" }}
        value={condition.value}
        placeholder={"Value"}
        name={"valueInput"}
        onChange={handleChange}
        size="small"
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  }

  if (condition.field === "metadata") {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: theme.spacing(3),
        }}
      >
        <RobotoTypography
          variant="body2"
          sx={{ marginRight: theme.spacing(2), maxWidth: "100px" }}
        >
          Metadata
        </RobotoTypography>

        {innerContent}
      </Box>
    );
  }

  if (isEmptyComparator) {
    return null;
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
      }}
    >
      <RobotoTypography
        variant="body2"
        sx={{ marginRight: theme.spacing(2), maxWidth: "100px" }}
      >
        Value
      </RobotoTypography>

      {innerContent}
    </Box>
  );
};
