import Box from "@mui/material/Box";
// Note(Pratik) Safe to use but interface may change once built into core MUI.
// Should verify this functionality upon migrating to MUI v6
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

import { DeletableBox } from "../DeletableBox";

import { Metadata } from "./types";
import { Value } from "./Value";

interface Props {
  metadata: Metadata;
  onChange?: (metadata: Metadata, keyDeleted?: string) => void;
  disabled?: boolean;
  onDeleteMetadata?: (key: string) => void;
}

export const MetadataView: React.FC<Props> = ({
  metadata,
  onChange,
  onDeleteMetadata,
  disabled = false,
}) => {
  function removeKeyFromMetadata(key: string) {
    const copy = { ...metadata };
    delete copy[key];

    if (onChange) {
      onChange(copy, key);
    }

    if (onDeleteMetadata) {
      onDeleteMetadata(key);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        margin: Object.keys(metadata).length > 0 ? "0.5rem 0 0.75rem" : 0,
      }}
      component="dl"
    >
      <Grid container spacing={1.5}>
        {Object.keys(metadata)
          .sort((a, b) => {
            return a.localeCompare(b);
          })
          .map((mKey) => {
            const onDelete = () => {
              if (!disabled) {
                removeKeyFromMetadata(mKey);
              }
            };

            const value = metadata[mKey];

            return (
              <Grid key={mKey}>
                <DeletableBox onDelete={onDelete} disabled={disabled}>
                  <dt
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    {mKey}
                  </dt>
                  <dd>
                    <Value value={value} />
                  </dd>
                </DeletableBox>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
