import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoButton, RobotoIconButton, RobotoTooltip } from "@/components";
import { ActionParameter } from "@/domain/actions";

const headerStyles = {
  fontWeight: 500,
  fontSize: "0.85rem",
};

const valueStyles = {
  fontSize: "0.85rem",
};

const ActionMetadataTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.foreground.main,
  padding: theme.spacing(1, 2),
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ActionMetadataTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ActionParamsTableProps {
  editable: boolean;
  mode: "parameters" | "values";
  params: ActionParameter[];
  setParams: (arg: ActionParameter[]) => void;
  removeParams?: string[];
  setRemoveParams?: (arg: string[]) => void;
  paramValues?: { [key: string]: unknown };
  setParamValues?: (arg: { [key: string]: unknown }) => void;
}

export const ActionParamsTable: React.FC<ActionParamsTableProps> = ({
  editable,
  mode,
  params,
  setParams,
  removeParams,
  setRemoveParams,
  paramValues,
  setParamValues,
}) => {
  const theme = useTheme();

  const originalParams = [...params];

  function isParamInOriginal(paramName: string) {
    for (const param of originalParams) {
      if (param.name === paramName) {
        return true;
      }
    }
    return false;
  }

  const cellStyles = {
    verticalAlign: "top",
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  };

  return (
    <>
      {params && params.length > 0 ? (
        <TableContainer
          sx={{
            mt: theme.spacing(1),
            mb: theme.spacing(1),
          }}
        >
          <Table size="small" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <ActionMetadataTableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ...headerStyles,
                    }}
                  >
                    Name
                  </Typography>
                </ActionMetadataTableCell>
                <ActionMetadataTableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ...headerStyles,
                    }}
                  >
                    Required
                  </Typography>
                </ActionMetadataTableCell>
                <ActionMetadataTableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ...headerStyles,
                    }}
                  >
                    Description
                  </Typography>
                </ActionMetadataTableCell>
                {mode === "parameters" && (
                  <ActionMetadataTableCell>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        ...headerStyles,
                      }}
                    >
                      Default
                    </Typography>
                  </ActionMetadataTableCell>
                )}
                {editable && mode === "parameters" && (
                  <ActionMetadataTableCell></ActionMetadataTableCell>
                )}
                {mode === "values" && (
                  <ActionMetadataTableCell>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        ...headerStyles,
                      }}
                    >
                      Value
                    </Typography>
                  </ActionMetadataTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {params?.map((param, idx) => {
                return (
                  <ActionMetadataTableRow key={idx}>
                    <TableCell sx={cellStyles}>
                      {editable && mode === "parameters" ? (
                        <TextField
                          id="outlined-parameter-name"
                          placeholder="Name"
                          size="small"
                          fullWidth
                          value={param.name || ""}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const tmpParameters = [...params];
                            tmpParameters[idx].name = event.target.value;
                            setParams(tmpParameters);
                          }}
                          inputProps={{
                            autoComplete: "off",
                            style: { ...valueStyles, height: "auto" },
                          }}
                        />
                      ) : (
                        <Typography variant="caption" sx={{ ...valueStyles }}>
                          {param.name}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell sx={cellStyles}>
                      {editable && mode === "parameters" ? (
                        <TextField
                          id="outlined-parameter-required"
                          select
                          size={"small"}
                          fullWidth
                          SelectProps={{
                            style: { ...valueStyles, lineHeight: "23px" },
                          }}
                          value={
                            (param.required !== undefined &&
                              param.required.toString()) ||
                            "false"
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const tmpParameters = [...params];
                            tmpParameters[idx].required =
                              event.target.value === "true";
                            setParams(tmpParameters);
                            if (event.target.value === "true") {
                              const tmpParameters = [...params];
                              tmpParameters[idx].default = undefined;
                              setParams(tmpParameters);
                            }
                          }}
                        >
                          <MenuItem value={"true"} sx={valueStyles}>
                            True
                          </MenuItem>
                          <MenuItem value={"false"} sx={valueStyles}>
                            False
                          </MenuItem>
                        </TextField>
                      ) : (
                        <Typography variant="caption" sx={{ ...valueStyles }}>
                          {(param.required !== undefined &&
                            param.required.toString()) ||
                            "false"}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell sx={cellStyles}>
                      {editable && mode === "parameters" ? (
                        <TextField
                          id="outlined-parameter-description"
                          placeholder="Description"
                          size="small"
                          fullWidth
                          multiline
                          value={param.description || ""}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const tmpParameters = [...params];
                            tmpParameters[idx].description = event.target.value;
                            setParams(tmpParameters);
                          }}
                          inputProps={{
                            autoComplete: "off",
                            style: valueStyles,
                          }}
                        />
                      ) : (
                        <Typography variant="caption" sx={{ ...valueStyles }}>
                          {param.description}
                        </Typography>
                      )}
                    </TableCell>
                    {mode === "parameters" && (
                      <TableCell sx={cellStyles}>
                        {editable ? (
                          <TextField
                            id="outlined-parameter-default"
                            placeholder="Default"
                            size="small"
                            fullWidth
                            disabled={param.required || false}
                            multiline
                            value={param.default || ""}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const tmpParameters = [...params];
                              tmpParameters[idx].default = event.target.value;
                              setParams(tmpParameters);
                            }}
                            inputProps={{
                              autoComplete: "off",
                              style: valueStyles,
                            }}
                          />
                        ) : (
                          <Typography variant="caption" sx={{ ...valueStyles }}>
                            {(param.default as string) || "N/A"}
                          </Typography>
                        )}
                      </TableCell>
                    )}
                    {mode === "values" && (
                      <TableCell
                        sx={{
                          minWidth: "200px",
                          ...cellStyles,
                        }}
                      >
                        {editable ? (
                          <TextField
                            id="outlined-parameter-value"
                            placeholder="Value"
                            size="small"
                            fullWidth
                            multiline
                            value={
                              (paramValues && paramValues[param.name]) ||
                              param.default ||
                              ""
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              if (setParamValues) {
                                setParamValues({
                                  ...paramValues,
                                  [param.name]: event.target.value,
                                });
                              }
                            }}
                            inputProps={{
                              autoComplete: "off",
                              style: valueStyles,
                            }}
                          />
                        ) : (
                          <Typography variant="caption" sx={{ ...valueStyles }}>
                            {(paramValues &&
                              (paramValues[param.name] as string)) ||
                              (param.default as string) ||
                              ""}
                          </Typography>
                        )}
                      </TableCell>
                    )}

                    {editable && mode === "parameters" && (
                      <TableCell sx={cellStyles}>
                        <RobotoTooltip title="Delete">
                          <span>
                            <RobotoIconButton
                              eventName={"ParameterDeleted"}
                              eventProperties={{
                                parameterName: param.name,
                              }}
                              onClick={() => {
                                const tmpParameters = [...params];
                                tmpParameters.splice(idx, 1);
                                setParams(tmpParameters);
                                // Only add the parameter to the remove params
                                // if it was in the original
                                if (isParamInOriginal(params[idx].name)) {
                                  if (removeParams && setRemoveParams) {
                                    setRemoveParams([
                                      ...removeParams,
                                      params[idx].name,
                                    ]);
                                  }
                                }
                              }}
                            >
                              <DeleteIcon
                                fontSize="small"
                                sx={{
                                  color: theme.palette.primary.main,
                                }}
                              />
                            </RobotoIconButton>
                          </span>
                        </RobotoTooltip>
                      </TableCell>
                    )}
                  </ActionMetadataTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {!editable && (
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              None
            </Typography>
          )}
        </>
      )}

      {editable && mode === "parameters" && (
        <RobotoButton
          size="small"
          variant="outlined"
          eventName={"AddFilterToTriggerClicked"}
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          onClick={() => {
            const tmpParameters = [...params];
            tmpParameters.push({} as ActionParameter);
            setParams(tmpParameters);
          }}
          sx={{
            mt: theme.spacing(1),
          }}
        >
          Add Parameter
        </RobotoButton>
      )}
    </>
  );
};
