import { AbortSignalForwarder } from "./abortSignal";
import {
  type CommandMessage,
  type MessagePayload,
  isMessageEvent,
} from "./messaging";

export type OnErrorCallback = (error: Error) => void;
export type OnMessageCallback = (event: MessageEvent<MessagePayload>) => void;

const noop = () => {};

interface WorkerManagerInit {
  worker: Worker | SharedWorker;
  onError: OnErrorCallback;
  onMessage: OnMessageCallback;
  signal: AbortSignal;
}

const workerIsShared = (
  worker: Worker | SharedWorker,
): worker is SharedWorker => {
  return "port" in worker;
};

/**
 * Coordinate the communication with a DedicatedWorker or SharedWorker.
 */
export class WorkerManager {
  #abortSignal: AbortSignal;
  #onErrorCallback: OnErrorCallback = noop;
  #onMessageCallback: OnMessageCallback = noop;
  #worker: Worker | SharedWorker;

  constructor(opts: WorkerManagerInit) {
    const { worker, onError, onMessage, signal } = opts;

    this.#abortSignal = signal;
    this.#onErrorCallback = onError;
    this.#onMessageCallback = onMessage;
    this.#worker = worker;

    this.#attachListeners();
  }

  dispose = () => {
    // SharedWorkers will be automatically terminated when all references to it are gone.
    // See note in https://developer.mozilla.org/en-US/docs/Web/API/SharedWorker, which
    // references the spec https://html.spec.whatwg.org/multipage/workers.html,
    // which details the behavior while describing the "onComplete" portion of the "Processing model"
    // https://html.spec.whatwg.org/multipage/workers.html#worker-processing-model
    if (!workerIsShared(this.#worker)) {
      this.#worker.terminate();
    }
  };

  sendCommand = <CommandType, DataType>(
    command: CommandMessage<CommandType, DataType>,
  ): void => {
    const { signal } = command;
    if (signal?.aborted) {
      return;
    }

    // Set up channel for aborting the request
    const abortSignalForwarder = new AbortSignalForwarder(
      signal ?? this.#abortSignal,
    );

    const message: MessagePayload<CommandType, DataType> = {
      data: command.data,
      correlationId: command.correlationId ?? self.crypto.randomUUID(),
      signalPort: abortSignalForwarder.forwardedPort,
      source: command.source,
      type: command.type,
    };
    const transferables = command.transferables ?? [];
    transferables.push(abortSignalForwarder.forwardedPort);

    const publisher = workerIsShared(this.#worker)
      ? this.#worker.port
      : this.#worker;
    publisher.postMessage(message, transferables);
  };

  sendCommandAwaitResponse = <CommandType, CommandDataType, ResponseData>(
    command: CommandMessage<CommandType, CommandDataType>,
  ): Promise<ResponseData> => {
    const id = self.crypto.randomUUID();
    const { signal } = command;

    return new Promise((resolve, reject) => {
      if (signal?.aborted) {
        reject(new DOMException("Aborted", "AbortError"));
        return;
      }

      // Register an ad hoc listener...
      const publisher = workerIsShared(this.#worker)
        ? this.#worker.port
        : this.#worker;

      // Stop listening for a response if this WorkerManager is disposed
      const removeListenerOnDispose = () => {
        publisher.removeEventListener("message", adHocListener);
        reject(new DOMException("Aborted", "AbortError"));
      };
      this.#abortSignal.addEventListener("abort", removeListenerOnDispose, {
        once: true,
      });

      // Reject this promise if the signal is aborted
      const rejectOnAbort = () => {
        reject(new DOMException("Aborted", "AbortError"));
      };
      signal?.addEventListener("abort", rejectOnAbort, { once: true });

      const adHocListener = (event: Event) => {
        if (!isMessageEvent<CommandType, ResponseData>(event)) {
          return;
        }
        const { data, error, correlationId } = event.data;
        if (correlationId !== id) {
          // Some other message/event from the worker
          return;
        }

        // ...and now remove this listener now that it's served its purpose
        publisher.removeEventListener("message", adHocListener);

        // Remove other listeners
        this.#abortSignal.removeEventListener("abort", removeListenerOnDispose);
        signal?.removeEventListener("abort", rejectOnAbort);

        if (error) {
          reject(new Error(error.message, { cause: error }));
          return;
        }
        resolve(data);
      };

      publisher.addEventListener("message", adHocListener, {
        signal, // Remove this event listener if the signal is aborted
      });

      this.sendCommand({
        ...command,
        correlationId: id,
      });
    });
  };

  #attachListeners = () => {
    const eventpublisher = workerIsShared(this.#worker)
      ? this.#worker.port
      : this.#worker;

    // Receive events / command responses back from worker
    eventpublisher.addEventListener("message", this.#onMessage, {
      signal: this.#abortSignal,
    });

    // Handle when message can't be deserialized
    // This would likely be a programming error on our part.
    eventpublisher.addEventListener("messageerror", this.#onMessageError, {
      signal: this.#abortSignal,
    });

    // Handle uncaught errors thrown in worker
    this.#worker.addEventListener("error", this.#onUnhandledWorkerError, {
      signal: this.#abortSignal,
    });
  };

  #onMessage = (event: Event) => {
    if (!isMessageEvent(event)) {
      return;
    }
    this.#onMessageCallback(event);
  };

  #onMessageError = (event: Event) => {
    const error = new Error("Something went wrong, please retry.", {
      cause: event,
    });
    this.#onErrorCallback(error);
  };

  #onUnhandledWorkerError = (event: Event) => {
    const error = new Error("Something went wrong, please retry.", {
      cause: event,
    });
    this.#onErrorCallback(error);
  };
}
