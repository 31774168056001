import AddModeratorIcon from "@mui/icons-material/AddModerator";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import ShieldIcon from "@mui/icons-material/Shield";
import StarsIcon from "@mui/icons-material/Stars";
import {
  Avatar,
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoTableCell, RobotoTableRow, RobotoTooltip } from "@/components";
import { UserLink } from "@/components/UserLink";
import { OrgInviteRecord } from "@/domain/orgs";
import { UserRecord } from "@/domain/users";
import { OrganizationRole, OrganizationRoleLevel } from "@/service/ApiService";
import { HTTPMethod } from "@/types";

export interface UsersTableProps {
  userListData?: OrganizationRole[];
  invitedListData?: OrgInviteRecord[];
  removeUserFromOrg?: (userId: string) => Promise<void>;
  modifyRoleForUser?: (
    userId: string,
    role: OrganizationRoleLevel,
    action: HTTPMethod,
  ) => Promise<void>;
  deleteInviteForUser?: (inviteId: string) => Promise<void>;
  currentUser: UserRecord | null;
  currentUserRole?: OrganizationRole;
  actionDialog: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
}

export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const theme = useTheme();

  const nonServiceOrgUsers =
    props.userListData?.filter(
      (orgUser) => orgUser.user.is_service_user === false,
    ) ?? [];

  if (nonServiceOrgUsers.length === 0) {
    return (
      <Box>
        <Skeleton
          animation="wave"
          sx={{ marginTop: 3 }}
          variant="rounded"
          height={100}
        />
      </Box>
    );
  }

  const ownerOrAdminOrSystemUser =
    props.currentUserRole?.roles?.includes("admin") ||
    props.currentUserRole?.roles?.includes("owner") ||
    props.currentUser?.is_system_user ||
    false;

  const ownerOrSystemUser =
    props.currentUserRole?.roles?.includes("owner") ||
    props.currentUser?.is_system_user ||
    false;

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <RobotoTableCell sx={{ width: "30%" }}>User</RobotoTableCell>
            <RobotoTableCell sx={{ width: "30%" }}>Name</RobotoTableCell>
            <RobotoTableCell sx={{ width: "20%" }}>Roles</RobotoTableCell>
            <RobotoTableCell sx={{ width: "20%" }}>Operations</RobotoTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nonServiceOrgUsers.map((orgUser) => {
            const roles = JSON.stringify(orgUser.roles);
            return (
              <RobotoTableRow key={orgUser.user.user_id}>
                <RobotoTableCell sx={{ backgroundColor: "#FF000" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(2),
                    }}
                  >
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      src={orgUser.user.picture_url}
                      imgProps={{ referrerPolicy: "no-referrer" }}
                    />
                    <UserLink userId={orgUser.user.user_id} />
                  </Box>
                </RobotoTableCell>
                <RobotoTableCell>{orgUser.user.name}</RobotoTableCell>
                <RobotoTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1.5),
                    }}
                  >
                    {roles.includes("user") && (
                      <RobotoTooltip title="Verified User">
                        <CheckCircleIcon
                          fontSize="small"
                          sx={{ color: theme.palette.secondary.main }}
                        />
                      </RobotoTooltip>
                    )}
                    <RoleIcons roles={roles} />
                  </Box>
                </RobotoTableCell>
                <RobotoTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1.5),
                    }}
                  >
                    {ownerOrAdminOrSystemUser && (
                      <>
                        {!roles.includes("admin") && (
                          <RobotoTooltip title="Make Admin">
                            <AddModeratorIcon
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                color: theme.palette.secondary.main,
                              }}
                              onClick={() =>
                                props.actionDialog(
                                  "Are you sure you want to promote this user?",
                                  "Please confirm that you would like to promote " +
                                    orgUser.user.user_id +
                                    " to being an administrator of your organization.",
                                  async function () {
                                    if (props.modifyRoleForUser) {
                                      await props.modifyRoleForUser(
                                        orgUser.user.user_id,
                                        "admin",
                                        "PUT",
                                      );
                                    }
                                  },
                                )
                              }
                            />
                          </RobotoTooltip>
                        )}

                        <ModeratorActions
                          ownerOrSystemUser={ownerOrSystemUser}
                          roles={roles}
                          user_id={orgUser.user.user_id}
                          actionDialog={props.actionDialog}
                          modifyRoleForUser={props.modifyRoleForUser}
                        />
                      </>
                    )}

                    {ownerOrSystemUser && !roles.includes("owner") && (
                      <RobotoTooltip title="Make Owner">
                        <StarsIcon
                          fontSize="small"
                          sx={{
                            cursor: "pointer",
                            color: theme.palette.secondary.main,
                          }}
                          onClick={() =>
                            props.actionDialog(
                              "Are you sure you want to promote this user?",
                              "Please confirm that you would like to promote " +
                                orgUser.user.user_id +
                                " to being an owner of your organization.",
                              async function () {
                                if (props.modifyRoleForUser) {
                                  await props.modifyRoleForUser(
                                    orgUser.user.user_id,
                                    "owner",
                                    "PUT",
                                  );
                                }
                              },
                            )
                          }
                        />
                      </RobotoTooltip>
                    )}

                    {ownerOrAdminOrSystemUser && !roles.includes("owner") && (
                      <RobotoTooltip title="Remove User">
                        <PersonRemoveIcon
                          data-cy={"remove-user"}
                          fontSize="small"
                          sx={{
                            cursor: "pointer",
                            color: theme.palette.secondary.main,
                          }}
                          onClick={() =>
                            props.actionDialog(
                              "Are you sure you want to remove this user?",
                              "Please confirm that you would like to remove " +
                                orgUser.user.user_id +
                                " from your organization.",
                              async function () {
                                if (props.removeUserFromOrg) {
                                  await props.removeUserFromOrg(
                                    orgUser.user.user_id,
                                  );
                                }
                              },
                            )
                          }
                        />
                      </RobotoTooltip>
                    )}
                  </Box>
                </RobotoTableCell>
              </RobotoTableRow>
            );
          })}

          {props.invitedListData &&
            props.invitedListData?.length > 0 &&
            props.invitedListData.map((invite: OrgInviteRecord) => {
              return (
                <RobotoTableRow key={invite.invite_id}>
                  <RobotoTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(2),
                        color: theme.palette.text.secondary,
                      }}
                    >
                      <Avatar sx={{ width: 30, height: 30 }} />
                      {invite?.user_id}
                    </Box>
                  </RobotoTableCell>
                  <RobotoTableCell
                    sx={{ width: "50px", color: theme.palette.text.secondary }}
                  >
                    Invitation Sent
                  </RobotoTableCell>
                  <RobotoTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        color: theme.palette.text.secondary,
                      }}
                    >
                      Pending
                    </Box>
                  </RobotoTableCell>
                  <RobotoTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1.5),
                      }}
                    >
                      {(ownerOrAdminOrSystemUser ||
                        invite.invited_by.user_id ===
                          props.currentUserRole?.user.user_id) && (
                        <RobotoTooltip title="Delete Invite">
                          <DeleteIcon
                            data-cy={"delete-invite"}
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              color: theme.palette.secondary.main,
                            }}
                            onClick={() =>
                              props.actionDialog(
                                "Are you sure you want to uninvite this user?",
                                "Please confirm that you would like to rescind the invitation to " +
                                  invite.user_id +
                                  " to join your organization.",
                                async function () {
                                  if (props.deleteInviteForUser) {
                                    await props.deleteInviteForUser(
                                      invite.invite_id,
                                    );
                                  }
                                },
                              )
                            }
                          />
                        </RobotoTooltip>
                      )}
                    </Box>
                  </RobotoTableCell>
                </RobotoTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const RoleIcons: React.FC<{ roles: string }> = ({ roles }) => {
  const theme = useTheme();

  if (roles.includes("owner")) {
    return (
      <RobotoTooltip title="Org Owner">
        <LocalPoliceIcon
          fontSize="small"
          sx={{ color: theme.palette.secondary.main }}
        />
      </RobotoTooltip>
    );
  } else if (roles.includes("admin")) {
    return (
      <RobotoTooltip title="Org Admin">
        <ShieldIcon
          fontSize="small"
          sx={{ color: theme.palette.secondary.main }}
        />
      </RobotoTooltip>
    );
  }

  return null;
};

const ModeratorActions: React.FC<{
  ownerOrSystemUser: boolean;
  roles: string;
  user_id: string;
  actionDialog: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
  modifyRoleForUser?: (
    userId: string,
    role: OrganizationRoleLevel,
    action: HTTPMethod,
  ) => Promise<void>;
}> = ({
  ownerOrSystemUser,
  roles,
  user_id,
  actionDialog,
  modifyRoleForUser,
}) => {
  const theme = useTheme();

  if (ownerOrSystemUser) {
    if (roles.includes("owner")) {
      return (
        <RobotoTooltip title="Remove Owner">
          <RemoveModeratorIcon
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: theme.palette.secondary.main,
            }}
            onClick={() =>
              actionDialog(
                "Are you sure you want to demote this user?",
                "Please confirm that you would like to demote " +
                  user_id +
                  " from being an owner of your organization.",
                async function () {
                  if (modifyRoleForUser) {
                    await modifyRoleForUser(user_id, "owner", "DELETE");
                  }
                },
              )
            }
          />
        </RobotoTooltip>
      );
    } else if (roles.includes("admin")) {
      return (
        <RobotoTooltip title="Remove Admin">
          <RemoveModeratorIcon
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: theme.palette.secondary.main,
            }}
            onClick={() =>
              actionDialog(
                "Are you sure you want to demote this user?",
                "Please confirm that you would like to demote " +
                  user_id +
                  " from being an administrator of your organization.",
                async function () {
                  if (modifyRoleForUser) {
                    await modifyRoleForUser(user_id, "admin", "DELETE");
                  }
                },
              )
            }
          />
        </RobotoTooltip>
      );
    }
  }

  return null;
};
