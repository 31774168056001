import BoltIcon from "@mui/icons-material/Bolt";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeselectIcon from "@mui/icons-material/Deselect";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Alert, Box, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { OrgRecord } from "@/domain/orgs";
import { useNavigation } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";
import { useLazyAPICall } from "@/service/apiHooks";
import { ErrorMonitoringService } from "@/service/ErrorMonitoringService";
import { File } from "@/state/visualization";
import {
  FileSystemNode,
  FileSignedUrlResponse,
  fileSignedUrlEndpoint,
  isFile,
  isDirectory,
} from "@/types";
import { pluralize } from "@/utils";
import { downloadFile } from "@/utils/downloadFile";
import { getSignedUrlParams } from "@/utils/signedUrlParams";

import { AlertDialog } from "./AlertDialog";
import { RobotoButton } from "./RobotoButton";
import { RobotoStyledPopoverMenu } from "./RobotoStyledPopoverMenu";

function transformSelectedItemsToActionInputs(items: FileSystemNode[]) {
  return items.map((fileSystemNode) => {
    if (isFile(fileSystemNode)) {
      return fileSystemNode.file.relative_path;
    } else if (isDirectory(fileSystemNode)) {
      let relativePath = fileSystemNode.directory.relative_path;
      if (relativePath.startsWith("/")) {
        relativePath = relativePath.slice(1); // Remove the leading slash
      }
      return `${relativePath}/*`;
    } else {
      return "";
    }
  });
}

interface SelectionButtonProps {
  datasetId?: string;
  selectedItems?: FileSystemNode[];
  selectedDatasets?: Set<string>;
  setSelectedDatasets?: (arg: Set<string>) => void;
  currentOrganization: OrgRecord | null;
  handleCollectionPopoverOpen?: (
    event: React.MouseEvent<HTMLElement>,
    overrideAnchorEl?: HTMLElement | null,
  ) => void;
  handleRemoveFromCollection?: () => void;
  handleDeleteItems?: () => void;
  enableDeleteDatasets?: boolean;
  clearSelected?: () => void;
  handleDeselectAll?: () => void;
}

export const SelectionButton: React.FC<SelectionButtonProps> = ({
  datasetId,
  selectedItems,
  selectedDatasets,
  setSelectedDatasets,
  currentOrganization,
  handleCollectionPopoverOpen,
  handleDeselectAll,
  handleRemoveFromCollection,
  handleDeleteItems,
  enableDeleteDatasets = false,
  clearSelected,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const navigate = useNavigate();
  const { goto } = useNavigation();
  const { datasetService } = React.useContext(DomainServicesContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { initiateRequest: initiateSignedUrlRequest } =
    useLazyAPICall<FileSignedUrlResponse>();

  const fetchSignedUrl = async (row: FileSystemNode) => {
    if (!isFile(row) || !currentOrganization) {
      return;
    }

    const file = row.file;

    const { data, error } = await initiateSignedUrlRequest({
      endpoint: fileSignedUrlEndpoint,
      method: "GET",
      orgId: currentOrganization?.org_id,
      queryParams: getSignedUrlParams(file, true),
      pathParams: { fileId: file.file_id },
    });

    if (!error && data?.data) {
      return data.data.url;
    }
  };

  const selectedItemsCount = React.useMemo(() => {
    return (selectedItems && selectedItems.length) || 0;
  }, [selectedItems]);

  const selectedDatasetsCount = React.useMemo(() => {
    return (selectedDatasets && selectedDatasets.size) || 0;
  }, [selectedDatasets]);

  const totalSelected = React.useMemo(() => {
    return selectedItemsCount + selectedDatasetsCount;
  }, [selectedDatasetsCount, selectedItemsCount]);

  const onClick = async () => {
    if (!selectedItems) {
      return;
    }
    for (const item of selectedItems) {
      const signedUrl = await fetchSignedUrl(item);

      if (signedUrl) {
        downloadFile(item.name, signedUrl);
        await new Promise((resolve) => setTimeout(resolve, 250));
      }
    }
    handleClose();
  };

  const errorInfo = (errorText: string) => {
    return (
      <Box
        sx={{
          marginTop: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      >
        <Alert severity="error">{errorText}</Alert>
      </Box>
    );
  };

  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogTitle, setAlertDialogTitle] = React.useState<string>("");
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogContent, setAlertDialogContent] =
    React.useState<React.ReactNode>();

  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();

  const deleteDatasets = async () => {
    if (!selectedDatasets || !currentOrganization) {
      return;
    }

    const selectedDatasetsArr = Array.from(selectedDatasets);

    for (const datasetId of selectedDatasetsArr) {
      try {
        await datasetService.deleteDataset(datasetId, {
          resourceOwnerId: currentOrganization.org_id,
        });
      } catch (error) {
        ErrorMonitoringService.captureError(error);
        setAlertDialogContent(
          errorInfo("Failed to delete dataset(s). Please try again later."),
        );
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    // All deletions finished without issue
    setAlertDialogOpen(false);
    navigate(0);
  };

  const handleDeleteDatasets = () => {
    if (!selectedDatasets) {
      return;
    }

    setAlertDialogContent(null);
    setAlertDialogTitle(
      `Delete ${pluralize("Dataset", selectedDatasets.size)}`,
    );
    setAlertDialogText(
      `Are you sure you want to delete ${selectedDatasets.size} ${pluralize("dataset", selectedDatasets.size)}?`,
    );
    setAlertDialogAction(() => deleteDatasets);

    // Close the selection popover and show the confirmation dialog
    handleClose();
    setAlertDialogOpen(true);
  };

  return (
    <>
      <RobotoButton
        id="selection-button"
        aria-controls={open ? "selection-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        eventName={"AddToCollectionOptionSelected"}
        sx={{
          width: "120px",
          minWidth: "120px",
          height: "32px",
          whiteSpace: "nowrap",
        }}
        disabled={totalSelected < 1}
      >
        Selected ({totalSelected})
      </RobotoButton>
      <RobotoStyledPopoverMenu
        id="selection-menu"
        MenuListProps={{
          "aria-labelledby": "selection-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            if (handleDeselectAll) {
              handleDeselectAll();
            }
            if (setSelectedDatasets) {
              setSelectedDatasets(new Set<string>());
            }
            if (clearSelected) {
              clearSelected();
            }
            handleClose();
          }}
        >
          <DeselectIcon />
          Deselect All
        </MenuItem>

        {selectedItemsCount > 0 && handleDeleteItems && (
          <MenuItem
            disableRipple
            disabled={selectedItems && selectedItems.length < 1}
            onClick={() => {
              if (!selectedItems) {
                return;
              }
              if (handleDeleteItems) {
                handleDeleteItems();
              }
              handleClose();
            }}
          >
            <DeleteIcon /> Delete Items
          </MenuItem>
        )}

        {selectedItemsCount > 0 && (
          <MenuItem
            disableRipple
            disabled={
              selectedItems &&
              (selectedItems.length < 1 ||
                selectedItems.filter(isFile).length === 0)
            }
            onClick={() => {
              void onClick();
            }}
          >
            <DownloadIcon /> Download Files
          </MenuItem>
        )}

        {enableDeleteDatasets && selectedDatasetsCount > 0 && (
          <MenuItem
            disableRipple
            disabled={selectedDatasets && selectedDatasets.size < 1}
            onClick={handleDeleteDatasets}
          >
            <DeleteIcon /> Delete Datasets
          </MenuItem>
        )}

        {selectedItems && selectedItemsCount > 0 && (
          <MenuItem
            disableRipple
            onClick={() => {
              const items = selectedItems
                .map((fileNode) => {
                  if (!isFile(fileNode)) {
                    return;
                  }

                  const file = fileNode.file;

                  const fileObj: File = {
                    fileId: file?.file_id || "",
                    relativePath: file?.relative_path || "",
                  };

                  return fileObj;
                })
                .filter((item) => item !== undefined);

              goto.visualize({
                files: items,
              });
            }}
            disabled={selectedItems.filter(isFile).length === 0}
          >
            <QueryStatsIcon /> Visualize Files
          </MenuItem>
        )}

        {datasetId && selectedItems && selectedItemsCount > 0 && (
          <MenuItem
            disableRipple
            onClick={() => {
              goto.invokeAction({
                datasetId: datasetId,
                inputFiles: transformSelectedItemsToActionInputs(selectedItems),
              });
            }}
          >
            <BoltIcon /> Invoke Action
          </MenuItem>
        )}

        {handleCollectionPopoverOpen && (
          <MenuItem
            disableRipple
            disabled={
              !(
                (selectedDatasets && selectedDatasets.size > 0) ||
                (selectedItems && selectedItems.filter(isFile).length > 0)
              )
            }
            onClick={(e) => {
              handleClose();
              handleCollectionPopoverOpen(e, anchorEl);
            }}
          >
            <DatasetOutlinedIcon />
            Add to Collection
          </MenuItem>
        )}

        {handleRemoveFromCollection && (
          <MenuItem
            disableRipple
            disabled={
              !(
                (selectedDatasets && selectedDatasets.size > 0) ||
                (selectedItems && selectedItems.filter(isFile).length > 0)
              )
            }
            onClick={() => {
              handleClose();
              handleRemoveFromCollection();
            }}
          >
            <DatasetOutlinedIcon />
            Remove from Collection
          </MenuItem>
        )}
      </RobotoStyledPopoverMenu>
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle={alertDialogTitle}
        dialogText={alertDialogText}
        dialogContent={alertDialogContent}
        dialogAction={alertDialogAction}
        suppressAutoClose={true}
      />
    </>
  );
};
