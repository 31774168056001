import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import * as React from "react";

import { FileNode } from "@/types";
import { basename } from "@/utils";

interface AnnotationSelectorProps {
  annotationFiles: FileNode[] | undefined;
  activeAnnotationFile: FileNode | undefined;
  onAnnotationFileSelected: (file: FileNode | undefined) => void;
}

export const AnnotationSelector: React.FC<AnnotationSelectorProps> = ({
  annotationFiles,
  activeAnnotationFile,
  onAnnotationFileSelected,
}) => {
  return (
    <FormControl sx={{ minWidth: 120, maxWidth: 180 }} size="small">
      <Select
        labelId="annotation-select-label"
        id="annotation-select"
        value={
          (activeAnnotationFile?.name &&
            basename(activeAnnotationFile?.name)) ||
          "None"
        }
        onChange={(event: SelectChangeEvent) => {
          if (event.target.value === "None") {
            onAnnotationFileSelected(undefined);
          } else {
            onAnnotationFileSelected(
              annotationFiles?.find(
                (record) => basename(record.name) === event.target.value,
              ),
            );
          }
        }}
        sx={{
          height: "32px",
          lineHeight: "1.75",
          fontSize: "0.8125rem",
        }}
      >
        <MenuItem
          value="None"
          sx={{
            fontSize: "0.85rem",
          }}
        >
          <em>None</em>
        </MenuItem>
        {annotationFiles?.map((record, i) => {
          return (
            <MenuItem
              key={i}
              value={basename(record.name)}
              sx={{
                fontSize: "0.85rem",
              }}
            >
              {basename(record.name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
