import { fetchAuthSession } from "aws-amplify/auth";
import {
  ExponentialBackoff,
  handleWhenResult,
  retry,
  type RetryPolicy,
} from "cockatiel";

import { HttpStatus } from "@/http/HttpClient";
import {
  APIErrorResponse,
  APIServiceError,
  LimitError,
  RobotoAPICall,
} from "@/types";

import { LoggerService } from "../LoggerService";

import { APIServiceInterface, AuthorizedRequest } from "./types";

export const APIService: APIServiceInterface = (function () {
  let instance: { authorizedRequest: AuthorizedRequest } | null = null;

  if (instance !== null) {
    return instance;
  }

  const baseURL = import.meta.env.VITE_API_URL as string | undefined;

  if (!baseURL) {
    throw new Error(
      "API URL not set. Check environment variable configuration.",
    );
  }

  const getAuthToken = async (): Promise<string> => {
    let token: string;

    try {
      const session = await fetchAuthSession();
      token = session.tokens?.idToken?.toString() ?? "";
    } catch (error) {
      LoggerService.error(
        "No auth credentials found. Unable to make auth'd request.",
        error,
      );
      throw new Error(
        "No auth credentials found. Unable to make auth'd request.",
        { cause: error },
      );
    }

    return token;
  };

  const constructHeaders = (token: string, apiCall: RobotoAPICall) => {
    const headers = apiCall.headers || {};
    headers["Content-Type"] = "application/json";
    headers["Authorization"] = `Bearer ${token}`;

    if (apiCall.orgId) {
      headers["X-Roboto-Org-Id"] = apiCall.orgId;
    }

    return headers;
  };

  const constructUrl = (apiCall: RobotoAPICall) => {
    const urlComponents = [baseURL, apiCall.apiVersion ?? "v1"];
    const path = apiCall.endpoint(apiCall.pathParams);
    if (path.startsWith("/")) {
      urlComponents.push(path.slice(1));
    } else {
      urlComponents.push(path);
    }

    let url = urlComponents.join("/");
    if (apiCall.queryParams) {
      const queryString = apiCall.queryParams.toString();
      url = `${url}?${queryString}`;
    }

    return url;
  };

  const handleResponseError = async (
    response: Response,
  ): Promise<{
    response: null;
    error: APIServiceError | Error | null;
  }> => {
    let errorResponse: APIErrorResponse | null = null;
    try {
      errorResponse = await (response.json() as Promise<APIErrorResponse>);
    } catch (e) {
      LoggerService.error("Unable to parse response body", e);
      return {
        response: null,
        error: new Error("Application error: Unable to parse response body"),
      };
    }
    const {
      error: {
        error_code = "Error",
        message = `Request failed with status ${response.status}. Please try again.`,
        stack_trace,
        resource_name,
        limit_quantity,
        current_quantity,
      },
    } = errorResponse;

    if (
      error_code === "RobotoLimitExceededException" &&
      resource_name !== undefined &&
      limit_quantity !== undefined &&
      current_quantity !== undefined
    ) {
      return {
        response: null,
        error: new LimitError(
          message,
          error_code,
          resource_name,
          limit_quantity,
          current_quantity,
          stack_trace,
        ),
      };
    }

    return {
      response: null,
      error: new APIServiceError(message, error_code, stack_trace),
    };
  };

  const retryPolicyFactory = (idempotent: boolean): RetryPolicy => {
    const isTransientHttpError = (res: unknown): boolean => {
      if (!(res instanceof Response)) {
        return false;
      }

      if (!idempotent) {
        if (
          [
            HttpStatus.REQUEST_TIMEOUT,
            HttpStatus.INTERNAL_SERVER_ERROR,
            HttpStatus.BAD_GATEWAY,
          ].includes(res.status)
        ) {
          return false;
        }
      }

      return [
        HttpStatus.REQUEST_TIMEOUT,
        HttpStatus.INTERNAL_SERVER_ERROR,
        HttpStatus.TOO_MANY_REQUESTS,
        HttpStatus.BAD_GATEWAY,
        HttpStatus.SERVICE_UNAVAILABLE,
        HttpStatus.GATEWAY_TIMEOUT,
      ].includes(res.status);
    };

    return retry(handleWhenResult(isTransientHttpError), {
      backoff: new ExponentialBackoff({
        maxDelay: 10_000, // 10s in ms
      }),
      maxAttempts: 5,
    });
  };

  const isRequestIdempotent = (apiCall: RobotoAPICall): boolean => {
    if (["GET", "PUT", "HEAD", "OPTIONS"].includes(apiCall.method)) {
      return true;
    }

    return false;
  };

  const authorizedRequest: AuthorizedRequest = async <ResponseType>(
    apiCall: RobotoAPICall,
  ): Promise<{
    response: ResponseType | null;
    error: APIServiceError | Error | null;
  }> => {
    let token;

    try {
      token = await getAuthToken();
    } catch {
      return {
        response: null,
        error: new Error(
          "No auth credentials found. Unable to make auth'd request.",
        ),
      };
    }

    const headers = constructHeaders(token, apiCall);

    const url = constructUrl(apiCall);

    try {
      const retryPolicy = retryPolicyFactory(isRequestIdempotent(apiCall));

      const request = new Request(new URL(url), {
        method: apiCall.method,
        headers: headers,
        body: apiCall.requestBody,
        signal: apiCall.signal,
      });

      const responsePromise = (): Promise<Response> => {
        return fetch(request.clone());
      };

      const response = await retryPolicy.execute<Response>(
        responsePromise,
        request.signal,
      );

      if (!response.ok) {
        return await handleResponseError(response);
      }

      if (response.status === 204) {
        return {
          response: null,
          error: null,
        };
      }

      const responseJSON = await (response.json() as Promise<ResponseType>);

      return {
        response: responseJSON,
        error: null,
      };
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === "AbortError") {
        return {
          response: null,
          error,
        };
      }
      LoggerService.error("API request failed", error);
      return { response: null, error: new Error("API request failed") };
    }
  };

  instance = {
    authorizedRequest,
  };

  return instance;
})();
