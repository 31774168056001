import CssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  ThemeProvider,
  SimplePaletteColorOptions,
} from "@mui/material/styles";
import { FC, useEffect, useMemo, useState } from "react";

import { ColorModeContext } from "./color";
import { lightTheme, darkTheme } from "./themes";
import { ZLayer } from "./types";

declare module "@mui/material/styles" {
  // In order to augment the existing Palette interface in MUI,
  // the changes need to be made to both Palette and PaletteOptions
  // https://mui.com/material-ui/customization/palette/#typescript

  export interface Palette {
    foreground: SimplePaletteColorOptions;
    navMenu: SimplePaletteColorOptions;
    toastHeader: SimplePaletteColorOptions;
    buttonHeader: SimplePaletteColorOptions;
    paper: SimplePaletteColorOptions;
    code: SimplePaletteColorOptions;
    tableHover: SimplePaletteColorOptions;
    hoverBackground: SimplePaletteColorOptions;
    panel: SimplePaletteColorOptions;
    tableRowBackground: {
      error: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
    };
  }

  export interface PaletteOptions {
    foreground: SimplePaletteColorOptions;
    navMenu: SimplePaletteColorOptions;
    toastHeader: SimplePaletteColorOptions;
    buttonHeader: SimplePaletteColorOptions;
    paper: SimplePaletteColorOptions;
    code: SimplePaletteColorOptions;
    tableHover: SimplePaletteColorOptions;
    hoverBackground: SimplePaletteColorOptions;
    panel: SimplePaletteColorOptions;
    tableRowBackground: {
      error: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
    };
  }

  export interface Theme {
    border: {
      thin: string;
      thick: string;
      radius: string;
      color: string;
    };
    navTopBarHeight: string;
    navSideBarWidth: string;
    actionButtonHeight: string;
    zLayer: (layer: ZLayer) => number;
  }

  export interface ThemeOptions {
    border: Partial<Theme["border"]>;
    navTopBarHeight: Theme["navTopBarHeight"];
    navSideBarWidth: Theme["navSideBarWidth"];
    actionButtonHeight: Theme["actionButtonHeight"];
    zLayer: Theme["zLayer"];
  }
}

interface MUIThemeProviderProps {
  children: React.ReactNode;
}

/**
 * Responsibilities:
 *  1. StyledEngineProvider: instruct MUI to inject its styles at the top of the head tag,
 *     allowing for overrides without the use of !important.
 *     Reference: https://mui.com/material-ui/integrations/interoperability/#css-injection-order-3
 *  2. CssBaseline: apply base styles
 *  3. ColorModeContext: provide React context for the color mode toggle
 *  4. ThemeProvider: provide the theme to the app
 */
export const MUIThemeProvider: FC<MUIThemeProviderProps> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setIsDarkMode(!isDarkMode);
      },
    }),
    [isDarkMode],
  );

  useEffect(() => {
    const matchDark = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(matchDark.matches);

    const handleChange = (event: MediaQueryListEvent) => {
      setIsDarkMode(event.matches);
    };

    matchDark.addEventListener("change", handleChange);

    return () => {
      matchDark.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};
