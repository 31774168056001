import { useContext } from "react";

import { Auth, AuthContext } from "./AuthContext";

export const useAuth = (): Auth => {
  return useContext(AuthContext);
};

export const useCurrentOrgId = (
  // This is optional so components which use this hook can fire an optional prop into this without doing any
  // further validation, and verify that it'll resolve to something meaningful.
  overrideOrgId?: string,
): string => {
  const auth = useAuth();

  if (overrideOrgId) {
    return overrideOrgId;
  }

  if (auth.currentOrganization?.org_id) {
    return auth.currentOrganization.org_id;
  }

  throw new Error(
    "Could not resolve an org_id from useAuth organization or from overrideOrgId",
  );
};
